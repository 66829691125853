
var tf = tf || {};

(function(app, $) {

    app.classes = {
        current:    'current',
        hidden:     'is-hidden',
        transition: 'has-transition',
        height0 :   'height-zero'
    };

    app.namespace = 'tf';

    app.events = {
        click: 'click.' + app.namespace
    };

    app.init = function() {
        app.bindPlugins();
        app.swiperInit();
        app.menuToggle();
        app.scrolledImage();
        app.validate();

        $('.collapsible').collapsible();

        $(".phone").mask("0(999) 999 99 99");

        jQuery.validator.addMethod("tagControl", function(value, element) {
            var tags = ["<html>","<body>","</body>","<script>","</script>","<head>","</head>","<br>","<form>","</form>","<hr>","<link>","<header>","</header>","<footer>","</footer>"],
                result = "";

            $.each(tags, function( index, arrayValue ){
                if(value.indexOf(arrayValue) > -1){
                    result = true;
                }
            });

            return !result;
            }, "Bu alanda HTML tagı olmamalıdır."
        );
    };

    app.modules = [];

    app.registerInit = function(module) {
        app.modules.push(module);
    };

    app.plugged = [
        {func: 'collapsible', target: '.collapsible'},
        {func: 'select', target: '.select-ur'},
        {func: 'tabview', target: '.tab-view'}
    ];

    app.initModules = function() {
        if(app.modules.length > 0) {
            app.modules.forEach(function(module) {
                if(typeof app[module] !== 'undefined' && !app[module].isInitialized()) {
                    app[module].init();
                }
            });
        }
    };


    $(".modal").uxrmodal({
        allowMultiple: true,
        onLoad       : function() {
            $('.uxr-modal-container').find('video')[0].play();
        }
    });


    app.bindPlugins = function() {
        $(app.plugged).each(function() {
            var e = this,
                p;

            if(typeof $.fn[e.func] !== 'function') {
                return;
            }

            if(typeof e.params !== 'undefined') {
                p = e.params;
            }

            $(e.target)[e.func](p);
        });
    };

    var windowSize = function () {
      return $(window).width();
    };

    app.swiperInit = function() {

        app.swiperInit.selectors =  [
            {
                swiper : '.main-slider' ,
                options: [
                    {
                        pagination: '.swiper-pagination',
                        spaceBetween: 30,
                        autoplay: 6000,
                        paginationClickable : true
                    }
                ]
            },
            {
                swiper : '.scrolled-image-container' ,
                options: [
                    {
                        spaceBetween: 30,
                        nextButton: '.swiper-button-next',
                        paginationClickable : true,
                        scrollbar: '.swiper-scrollbar',
                        scrollbarHide: true,
                        slidesPerView: 'auto',
                        centeredSlides: true,
                        grabCursor: true,
                        mousewheelControl: true
                    }
                ]
            }
        ];

        $.each(app.swiperInit.selectors, function (index, value) {
           var opts = {};
           $.each(value.options, function(i,v){
               opts = v;
           });
           index = new Swiper(value.swiper , opts);
        });
    };

    app.menuToggle = function(){

        app.menuToggle.selectors =  {
            navToggle : '.main-nav-toggle',
            mainNavigation : '.main-nav'
        };

        app.menuToggle.variables = {
            state : true
        };

        $(window).on('resize', function () {
          //menuControl();
        });

        $(app.menuToggle.selectors.navToggle).on(app.events.click, function () {
           menuToggle();
        });

        var menuToggle =  function() {
            if(app.menuToggle.state===false) {
                $(app.menuToggle.selectors.mainNavigation).removeClass(app.classes.height0);
            }
            else {
                $(app.menuToggle.selectors.mainNavigation).addClass(app.classes.height0);
            }
            app.menuToggle.state= !app.menuToggle.state;
        };

        var menuControl = function() {
            if(windowSize()<769){
                $(app.menuToggle.selectors.mainNavigation).addClass(app.classes.height0);
                app.menuToggle.state = false;
            }
            else {
                $(app.menuToggle.selectors.mainNavigation).removeClass(app.classes.height0);
                app.menuToggle.state = true;
            }
        };

        menuControl();
    };


    app.scrolledImage = function () {
       var mouseBtn = $('.scroll-btn');

       mouseBtn.on('click', function () {
           var _this = $(this);
           _this.addClass(app.classes.hidden);
       });
    };

    app.validate = function () {
        $("#contact_form").validate({
            errorPlacement: function(error, element) {
                // Append error within linked label
                $( element )
                    .closest( "form" )
                    .find( "span[for='" + element.attr( "id" ) + "']" )
                    .append( error );
            },
            errorElement: "span",
            rules: {
                name: 'required',
                email: {
                    required: true,
                    email: true
                },
                phone: {
                    required: true,
                    minlength: "15"
                },
                place: 'required',
                work: 'required',
                teamname: 'required',
                teaminfo: 'required',
                project: 'required',
                cv: 'required',
                check: 'required'
            },
            messages: {
                name: ' (Zorunlu alandır.)',
                email: ' (Email formatı uygun değil.)',
                phone: {
                    required: ' (Zorunlu alandır.)',
                    minlength: ' (Telefon formatı uygun değil.)'
                },
                place: ' (Zorunlu alandır.)',
                work: ' (Zorunlu alandır.)',
                teamname: ' (Zorunlu alandır.)',
                teaminfo: ' (Zorunlu alandır.)',
                project: ' (Zorunlu alandır.)',
                cv: ' (Zorunlu alandır.)',
                check: ' (Başvurunuzu göndermeden önce kullanıcı sözleşmesini kabul etmelisiniz.) '
            }
        });
    };

    app.validate = function () {
        $(".valid_form").validate({
            rules: {
                firstname: "required",
                lastname: "required",
                username: {
                    required: true,
                    minlength: 2
                },
                tagControl : {
                    tagControl : true
                },
                password: {
                    required: true,
                    minlength: 5
                },
                confirm_password: {
                    required: true,
                    minlength: 5,
                    equalTo: "#password"
                },
                email: {
                    required: true,
                    email: true
                },
                topic: {
                    required: "#newsletter:checked",
                    minlength: 2
                },
                agree: "required"
            },
            messages: {
                firstname: "Please enter your firstname",
                lastname: "Please enter your lastname",
                username: {
                    required: "Please enter a username",
                    minlength: "Your username must consist of at least 2 characters"
                },
                password: {
                    required: "Please provide a password",
                    minlength: "Your password must be at least 5 characters long"
                },
                confirm_password: {
                    required: "Please provide a password",
                    minlength: "Your password must be at least 5 characters long",
                    equalTo: "Please enter the same password as above"
                },
                email: "Please enter a valid email address",
                agree: "Please accept our policy",
                topic: "Please select at least 2 topics"
            },
            submitHandler: function(form) {
                var form = $(".contact_form");
                var params = form.serializeArray();
                var formData = new FormData();

                if($("#file").length > 0) {
                    var files = $("#file")[0].files;
                    for (var i = 0; i < files.length; i++) {
                        formData.append("invoice", files[i]);
                    }
                }

                $(params).each(function (index, element) {
                    formData.append(element.name, element.value);
                });

                var url = $(form).attr('action');
                event.preventDefault();
                $('.loading').show();
                $.ajax({
                    type: "post",
                    url: url,
                    data: formData,
                    contentType: false,
                    processData: false,
                    success: function(data)
                    {
                        console.log(data);
                        alert(data);
                        $('form').find("input[type=text],input[type=email], textarea").val("");
                        $('.loading').hide();
                        location.reload();
                    },
                    error: function(data){
                        console.log("error");
                        console.log(data);
                    }

                });
                return false;
            }
        });
    };



})(tf, jQuery);

(function ($) {
    $(function() {
        tf.init();
    });
})(jQuery);
